import React from 'react'
import { FaMicrochip, FaServer, FaRocket, FaAward, FaPaperPlane, FaSitemap } from 'react-icons/fa'

import Image1 from './../assets/images/logo.png'
import Image2 from './../assets/images/intel.png'
import Image3 from './../assets/images/cbyx.jpg'
import Image4 from './../assets/images/amboss.png'

const data = [
  {
    date: '2018',
    headline: 'Docbot',
    website: 'https://www.docbot.co',
    description: `Realtime and cloud AI for Gastroenterology`,
    image: Image1,
    icon: <FaRocket />,
  },
  {
    date: '2017',
    headline: 'Amboss',
    website: 'https://www.amboss.com',
    description: `Medical Education Tool Development`,
    image: Image4,
    icon: <FaAward />,
  },
  {
    date: '2016',
    headline: 'Congress Bundestag Fellowship',
    description: `Government Fellowship in Germany`,
    image: Image3,
    icon: <FaAward />,
  },
  {
    date: '2013',
    headline: 'Intel',
    website: 'https://www.intel.com',
    description: `ASIC and FPGA design`,
    image: Image2,
    icon: <FaServer />,
  },
  // {
  //   date: '2013',
  //   headline: 'Intel',
  //   description: `Functional/DFT design and FPGA prototyping of a new memory architecture`,
  //   image: Image2,
  //   icon: <FaSitemap />,
  // },
  // {
  //   date: '2012',
  //   headline: 'Broadcom',
  //   website: 'https://www.broadcom.com',
  //   description: `Failure Analysis Engineering`,
  //   image: Image4,
  //   icon: <FaMicrochip />,
  // }
]

export default data
